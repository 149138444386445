import { useState, useEffect } from "react";
import logo1 from "../../Assets/logo2.png";
import { InputGroup, Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UseFormValidations } from "../../FormValidation/UseFormValidations";
import "./Auth.css";
import { forgetPassword, login, updatePassword } from "../../Config/ValidationSchema";
import { notify, save } from "../../Config/SharedFunctions";
import { urls } from "../../Config/Consts";
import { returnErrorColor } from "../../Config/SmallFunctions";

const ChangePassword = ({close}) => {
  const navigate = useNavigate();
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");

  const submit = async () => {
    if (data?.currentPassword == data?.newPassword) {
      notify("", "Current Password and New Password should not be Same");
    } else {
      let res = await save(urls.Auth.UpdatePassword, data);
      if (res?.data?.status == true) {
        close()
      }
    }
  };
  const { data, errors, handleSubmit, formChange } = UseFormValidations({
    validationSchema: updatePassword,
    submit: submit,
  });

  return (
    <section className="vh-100 ">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <nav className="navbar navbar-dark primary-colors" style={{ height: "55px" }}>
            <div className="container d-flex justify-content-center">
              <span className="myStyle text-center" style={{ fontSize: "25px", color: "whitesmoke" }}>
                <i className="fa fa-users " /> Human Resource Management System
              </span>
            </div>
          </nav>
           
          <Col lg={12} xs={12} md={6} className="d-flex justify-content-center">
            <Card className="  px-sm-3 px-md-4 px-lg-5 py-lg-3 my-lg-5 my-md-2 mt-1" style={{ backgroundColor: "#e9e9e9" }}>
              <Card.Body>
                <div className="text-center mb-xl-1 mb-lg-1">
                  <img src={logo1} height={150} />
                </div>

                <Form id="login-form" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Current Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type1} name="currentPassword" required placeholder="Current Password" className={`input-heigt password ${returnErrorColor(errors?.currentPassword)}`} onChange={formChange("")} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type2} name="newPassword" required placeholder="New Password" className={`input-heigt password ${returnErrorColor(errors?.newPassword)}`} onChange={formChange("")} />
                      <InputGroup.Text>
                        <span onClick={() => setType2(type2 == "password" ? "text" : "password")} id="togglePassword">
                          {type2 == "password" ? <GoEyeClosed /> : <GoEye />}
                        </span>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <div className="text-center">
                    <Button onClick={handleSubmit} size="lg" className="btn-size mt-3" variant="light" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
