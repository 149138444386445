import { lazy, Suspense, useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import "./Services/Index.css"
import Login from "./Pages/Auth/Login";
import { HubConnectionBuilder } from "@microsoft/signalr";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ChangePassword from "./Pages/Auth/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "./Config/SharedFunctions";
import { chatUrl, urls } from "./Config/Consts";
import { updateMessages } from "./Store/Action";
import { getUserfromSS, returningValue } from "./Config/SmallFunctions";
import moment from "moment";

const RC = lazy(() => import("./Pages/RouteConfig"));
const Privacy = lazy(() => import("./Pages/Auth/Privacy"));

const App = () => {
  const dispatch = useDispatch()
  const messages = useSelector((state) => state.messages)
  const loggedInUserId = useSelector((state) => state.loggedInUserId)
  const allMessages = useRef(null);
  allMessages.current = messages;


  const getChat = async () => {
    let res = await getList(urls.chat.getAll, { id: getUserfromSS()?.empId });
    res = res?.map((e) => ({ ...e, createdDate: returningValue(e?.createdDate, "LocalTime") }))
    dispatch(updateMessages(res))
  };

  useEffect(() => {
    if (loggedInUserId) {
      let connection = new HubConnectionBuilder().withUrl(chatUrl).build();
      // connection.on("UpdateOnlineUsers", (onlineUsers) => {
      //   // Handle the updated list of online users
      //   console.log("Online Users:", onlineUsers);
      //   // You can update your state or UI with the list of online users here
      // });

      connection.on("ReceiveChat", (updatedMessage) => {
        let res = [...updatedMessage]
        let userId = getUserfromSS()?.empId
        res = res?.filter((v) => [v?.senderId, v?.receiverId]?.some((a) => a == userId))
        res = res?.map((e) => ({ ...e, createdDate: moment.utc(e?.createdDate, "YYYY-MM-DDTHH:mm:ss").local().format('YYYY-MMM-DD H:mm:ss') }))
        dispatch(updateMessages(res))
      });
      connection.start().then(() => console.log("Connection established.")).catch((err) => console.error("Error establishing connection:", err));
    }
  }, [loggedInUserId]);

  useEffect(() => {
    loggedInUserId > 0 && getChat()
  }, [loggedInUserId])
  return (
    <>
    {/* <div className="loading-indicator">

    </div> */}
      <Suspense fallback={() => <div>Loading ....</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="/createpassword/:token" element={<ForgotPassword />} />
            <Route path="v1/*" element={<RC />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </Suspense >
    </>
  );
}

export default App;
