import logo1 from "../../Assets/logobgremove.png";
import Loginz from "../../Assets/loginzTrans.png";
import PrimeAAc from "../../Assets/PrimeAAC.png";

import { InputGroup, Form, Col, Card, Button } from "react-bootstrap";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UseFormValidations } from "../../FormValidation/UseFormValidations";
import "./Auth.css";
import { login } from "../../Config/ValidationSchema";
import { getList, notify, save, saveWithoutMessage } from "../../Config/SharedFunctions";
import { urls } from "../../Config/Consts";
import { updateLoggedInUser } from "../../Store/Action";
import { useDispatch } from "react-redux";
import { IoIosArrowRoundForward } from "react-icons/io";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState("password");
  
  const submit = async () => {
    let res = await saveWithoutMessage(urls?.Auth?.login, data)
    if (res?.data?.status == true) {
        if ([1, 2]?.some(e => e == res?.data?.data?.user?.roleId)) {
            notify(true, "Logged in Successfully.")
            let resData = res?.data?.data;
            sessionStorage.setItem("token", resData?.token);
            sessionStorage.setItem("user", JSON.stringify(resData?.user));
            dispatch(updateLoggedInUser(resData?.user?.empId));
            let res1 = await getList(urls.userAccess.getUserAccessLinks, { subscriptionId: resData?.user?.roleId });
            let links = res1?.filter((v) => v?.status == "Active")?.map((e) => e.linkName);
      
            sessionStorage.setItem("accessLinks", JSON.stringify(links));
            navigate("/v1/dashboard");
        } else notify(false, "You don't have Web access!!")
    }
    else notify(false, "Failed to login. You have entered incorrect credentials.")
}

  const submit1 = async () => {
    let res = await save(urls?.Auth?.login, data);
    if (res?.data?.status == true) {
      let resData = res?.data?.data;
      sessionStorage.setItem("token", resData?.token);
      sessionStorage.setItem("user", JSON.stringify(resData?.user));
      dispatch(updateLoggedInUser(resData?.user?.empId));
      let res1 = await getList(urls.userAccess.getUserAccessLinks, { subscriptionId: resData?.user?.roleId });
      let links = res1?.filter((v) => v?.status == "Active")?.map((e) => e.linkName);

      sessionStorage.setItem("accessLinks", JSON.stringify(links));
      navigate("/v1/dashboard");
    }
  };

  const forgotPassword = async () => {
    if (data?.email && data?.email != "") {
      let body = { serverName: window.location.hostname, email: data?.email };
      let res = await save(urls?.Auth?.SendForgotPasswordMail, body);
    } else {
      notify(false, "Please enter email");
    }
  };
  const { data, errors, handleSubmit, formChange } = UseFormValidations({
    validationSchema: login,
    submit: submit,
  });

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user");
    if (loggedInUser) {
      navigate("/v1/dashboard");
    }
  }, []);

  return (
    <section className="vh-100 background-image">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-between">
          
            {/* <div className="container d-flex justify-content-start border">
              <span className="myStyle text-start border" style={{ fontSize: "25px", color: "whitesmoke" }}>
                <img src={PrimeAAc} width={100} height={50} />
              </span>
            </div> */}
          
          <Col lg={8} xs={12} md={6}>
          <img className="mt-2" src={PrimeAAc} height={100} />
           
          </Col>
          <Col lg={4} xs={12} md={6} className="background-shade">
            <Card className="transparent-card  px-lg-5 py-lg-3 my-lg-5 my-md-2">
              <Card.Body className="transparent-card ">
                <div className="text-start mb-xl-5 mb-lg-4 mt-lg-5">
                  {/* <img src={Loginz} height={100} /> */}
                 <h1 className="text-white"> Welcome Back  </h1>
                 <h5 className="text-white">Existing member Login</h5>
                </div>

                <Form id="login-form" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Email </Form.Label>
                    <InputGroup hasValidation  >
                      <Form.Control size="lg" type="email" required id="email" name="email" className="input-heigt backgroundIn" placeholder="Enter Email" onChange={formChange("")} />
                    </InputGroup>
                    {errors && errors?.email && <Form.Control.Feedback type="invalid">{errors?.email}</Form.Control.Feedback>}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type} name="password" required placeholder="Enter Password" className="input-heigt password backgroundIn" onChange={formChange("")} />
                      <InputGroup.Text>
                        <span onClick={() => setType(type == "password" ? "text" : "password")} id="togglePassword">
                          {type == "password" ? <GoEyeClosed /> : <GoEye />}
                        </span>
                      </InputGroup.Text>
                      {errors && errors?.password && <Form.Control.Feedback type="invalid">{errors?.password}</Form.Control.Feedback>}
                    </InputGroup>
                  </Form.Group>
                  <div className="text-end ">
                    <p onClick={forgotPassword} className="forgot-password text-danger pointer">
                      Forgot Password ?
                    </p>
                  </div>
                  <div className="text-center">
                    <Button onClick={handleSubmit} size="lg" className="btn-size mt-3 w-100 d-flex justify-content-between align-items-center" variant="light" type="submit">
                      <span>Log in</span> <IoIosArrowRoundForward size={25} />
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
    </section>
  );
}

export default Login;
