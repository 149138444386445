export const login = {
  email: {
    required: {
      value: true,
      message: "Please enter email",
    },
    pattern: {
      value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      message: "Please enter valid email",
    },
  },
  password: {
    required: {
      value: true,
      message: "Please enter password",
    },
  },
};
export const updatePassword={
  currentPassword:{
    required:{value:true,
      message:"Please Enter Current Password"
    }
  },
  newPassword:{
    required:{value:true,
      message:"Please Enter New Password"
    }
  }
}
export const forgetPassword={
  password:{
    required:{
      value:true,
      message:"Please Enter New Password"
    }
  },
  confirmPassword:{
    required:{
      value:true,
      message:"Please Enter Confirm Password"
    }
  }
}
export const employee = {
  jobCategory:{
    required: {
      value: true,
      message: "Please enter employment type",
    },
  },
  employeeId: {
    required: {
      value: true,
      message: "Please enter employee Id",
    },
  },
  firstName: {
    required: {
      value: true,
      message: "Please enter first name",
    },
  },
  timeZoneId: {
    required: {
      value: true,
      message: "Please enter timeZoneId",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Please enter last name",
    },
  },
  personalEmail: {
    required: {
      value: true,
      message: "Please Enter Personal Email",
    },
  },
  officialEmail: {
    required: {
      value: true,
      message: "Please Enter Official Email",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Please Enter Gender",
    },
  },
  contactNo: {
    required: {
      value: true,
      message: "Please Enter Contact No.",
    },
  },
  fatherName: {
    required: {
      value: true,
      message: "Please Enter Father Name.",
    },
  },
  workType: {
    required: {
      value: true,
      message: "Please Enter Work Type.",
    },
  },
  roleId: {
    required: {
      value: true,
      message: "Please Select Role.",
    },
  },
  departmentId: {
    required: {
      value: true,
      message: "Please Select Department.",
    },
  },
  designationId: {
    required: {
      value: true,
      message: "Please Select Designation.",
    },
  },
  dobOriginal: {
    required: {
      value: true,
      message: "Please Select Date Of Joining.",
    },
  },
  dobCertificate: {
    required: {
      value: true,
      message: "Please Select Date Of Joining.",
    },
  },
  dateOfJoining: {
    required: {
      value: true,
      message: "Please Select Date Of Joining.",
    },
  },
  baseLocation: {
    required: {
      value: true,
      message: "Please Enter base Location.",
    },
  },
  reportingManagerId: {
    required: {
      value: true,
      message: "Please Select Reporting Manager",
    },
  },
};

export const education = {
  school: {
    required: {
      value: true,
      message: "Please Enter School/College",
    },
  },
  board: {
    required: {
      value: true,
      message: "Please Enter Board/University",
    },
  },
  grade: {
    required: {
      value: true,
      message: "Please Enter Grade",
    },
  },
  startMonth: {
    required: {
      value: true,
      message: "Please Enter Start Date",
    },
  },
  startYear: {
    required: {
      value: true,
      message: "Please Enter Start Date",
    },
  },
  endtMonth: {
    required: {
      value: true,
      message: "Please Enter End Date",
    },
  },
  endYear: {
    required: {
      value: true,
      message: "Please Enter End Date",
    },
  },
};
export const timeSheetDetails = {
   
  hours: {
    required: {
      value: true,
      message: "Please Enter Task",
    },
  },
  minutes: {
    required: {
      value: true,
      message: "Please Enter Task",
    },
  },
  description: {
    required: {
      value: true,
      message: "Please Enter Task",
    },
  },
};
export const approval={
  approvalComments:{
    required:{
      value:true,
    message:"Please Write Comments"}
  }
}
export const applyLeave={
  leaveTypeId:{
    required:{
      value:true,
      message:"Please Select Leave Type"
    },

  },
  // halfDayType:{
  //   required:{
  //     value:true,
  //     message:"Please Select Half Day Type"
  //   }
  // },
  startDate:{
    required:{
      value:true,
      message:"Please Select From Date"
    }
  },
  endDate:{
    required:{
      value:true,
      message:"Please Select To Date"
    }
  },
  reasonForLeave:{
    required:{
      value:true,
      message:"Please Write Reason"
    }
  }

}
export const clients={
  email:{
    required:{
      value:true,
      message:"Please Enter Email"
    },
    pattern: {
      value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: "Email Formate is required"
    }
  },
  phone:{
    required:{
      value:true,
      message:"Please Enter phone"
    }
  }
}
export const allreports={
  fromDate:{
    required:{
      value:true,
      message:"Please Enter fromDate"
    }
  },
  toDate:{
    required:{
      value:true,
      message:"Please Enter toDate"
    }
  }
}
export const allLeavereports={
  startDate:{
    required:{
      value:true,
      message:"Please Enter fromDate"
    }
  },
  endDate:{
    required:{
      value:true,
      message:"Please Enter toDate"
    }
  }
}
export const organization={
  phone:{
    required:{
      value:true,
      message:"Please Enter phone"
    }
  },
  
}
export const workLocations={
  locationPhone:{
    required:{
      value:true,
      message:"Please Enter locationPhone"
    }
  },
  
}

export const salarytrack={
  currentSaraly:{
    required:{
      value:true,
      message:"Please Enter Current Salary"
    }
  },
  incrementPercentage:{
    required:{
      value:true,
      message:"Please Enter Increment Salary"
    }
  },
  effectiveFrom:{
    required:{
      value:true,
      message:"Please From Enter Date"
    }
  },
  effectiveTo:{
    required:{
      value:true,
      message:"Please Enter To Date"
    }
  },
  designationId:{
    
      required:{
        value:true,
        message:"Please Enter To Date"
      }
  }
}
export const supplyRequest = {
  supplyRequestEntities: {
    isArray: {
      value: true,
      message: "Required",
    },
    innerSchema: {
      school: {
        required: {
          value: true,
          message: "Please Enter itemCode",
        },
      },
      board: {
        required: {
          value: true,
          message: "Please Enter quantity",
        },
      },
    }
  },
}