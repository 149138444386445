import { useState, useEffect } from "react"; 
import logo1 from "../../Assets/logo2.png"; 
import { InputGroup, Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UseFormValidations } from "../../FormValidation/UseFormValidations";
import "./Auth.css";
import { forgetPassword, login } from "../../Config/ValidationSchema";
import { notify, save } from "../../Config/SharedFunctions";
import { urls } from "../../Config/Consts";
import { returnErrorColor } from "../../Config/SmallFunctions";
 
const ForgotPassword=()=> {
  const navigate = useNavigate();
  const { token } = useParams();
   const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
 

  const submit = async () => {
    if (data?.password == data?.confirmPassword) {
      let body = { token: token, password: data?.password };
      let res = await save(urls.Auth.CreatePassword, body);
      if (res?.data?.status == true) {
        navigate("/");
      }
    } else {
      notify(false, "Password and confirm password should be same");
    }
  };  
  const { data, errors, handleSubmit, formChange } = UseFormValidations({
    validationSchema:forgetPassword,
    submit: submit,
  }); 
  

  return (
    <section className="vh-100 ">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <nav className="navbar navbar-dark primary-colors" style={{ height: "55px" }}>
            <div className="container d-flex justify-content-center">
              <span className="myStyle text-center" style={{ fontSize: "25px", color: "whitesmoke" }}>
                <i className="fa fa-users " /> Human Resource Management System 
              </span>
              
            </div>
          </nav>
          <Col lg={8} xs={12} md={6}>
            <div className="  ">
              <iframe src="https://embed.lottiefiles.com/animation/93794" className="gif w-100 iframe" width={250}></iframe>
            </div>
          </Col>
          <Col lg={4} xs={12} md={6} className="d-flex justify-content-center">
            <Card className="  px-sm-3 px-md-4 px-lg-5 py-lg-3 my-lg-5 my-md-2 mt-1" style={{ backgroundColor: "#e9e9e9" }}>
              <Card.Body>
                <div className="text-center mb-xl-1 mb-lg-1">
                   <img src={logo1} height={150}/>
                </div>

                <Form id="login-form" onSubmit={handleSubmit}>
                  

                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type1} name="password" required placeholder="Password" className={`input-heigt password ${returnErrorColor(errors?.password)}`} onChange={formChange("")} />
                      {/* <InputGroup.Text>
                        <span onClick={() => setType1(type1 == "password" ? "text" : "password")} id="togglePassword">
                          {type1 == "password" ? <GoEyeClosed /> : <GoEye />}
                        </span>
                      </InputGroup.Text> */}
                      {/* {errors && errors?.newPassword && <Form.Control.Feedback type="invalid">{errors?.newPassword}</Form.Control.Feedback>} */}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type2} name="confirmPassword" required placeholder="Confirm Password" className={`input-heigt password ${returnErrorColor(errors?.confirmPassword)}`} onChange={formChange("")} />
                      <InputGroup.Text>
                        <span onClick={() => setType2(type2 == "password" ? "text" : "password")} id="togglePassword">
                          {type2 == "password" ? <GoEyeClosed /> : <GoEye />}
                        </span>
                      </InputGroup.Text>
                      {/* {errors && errors?.confirmPassword && <Form.Control.Feedback type="invalid">{errors?.confirmPassword}</Form.Control.Feedback>} */}
                    </InputGroup>
                  </Form.Group>
                   
                  <div className="text-center">
                    <Button onClick={handleSubmit} size="lg" className="btn-size mt-3" variant="light" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
