import moment from "moment/moment";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";

export const returningValue = (v, type) => {
  if (v && v != "" && v != undefined && v != null && v != "null") {
    switch (type) {
      case "Bool" : return v == "true"?true:false
      case "Date":return new Date(v) != "Invalid Date" ? new Date(v) : "";
      case "Phone":return v?.toString()?.replace(/[^0-9]/g, "");
      case "Number":return parseInt(v);
      case "LocalTime": return moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('YYYY-MMM-DD H:mm:ss')
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString": v?.toString();
      default:return v;
    }
  }
  return "";
};
export const returningValue1 = (value, defaultValue) => {
  return value !== undefined ? value : defaultValue;
};

export const decryPtiedLinks = () => {
  let links = sessionStorage.getItem("accessLinks");
  if (links && links != undefined && links != "") {
    return JSON.parse(links)
    var bytes = CryptoJS.AES.decrypt(links, "COLINA");
    console.log(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)),"here")
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return [];
  }
};

export const CheckLinks = (linkNames) =>  decryPtiedLinks()?.some((v) => linkNames?.some((a) => a == v));

// export const CheckLinks = (linkNames) => {
//   for (let i = 0; i < linkNames.length; i++) {
//     if (decryPtiedLinks()?.includes(linkNames[i])) {
//       return true;
//     } else if (i + 1 == linkNames.length) {
//       return false;
//     }
//   }
//   // return decryPtiedLinks()?.includes(linkName)
// };

export const returnErrorColor = (condition) => {
  let v = condition;
  return v && v != undefined && v != null && v != ""
    ? "border border-danger"
    : "";
};
// export const returnValuewithType = (v, type) => {
//   if (v && v != "" && v != undefined && v != null) {
//     switch (type) {
//       case "Date":
//         return new Date(v) != "Invalid Date"
//           ? moment(v).format("MM-DD-YYYY")
//           : v;
//       case "Phone":
//         return v?.toString()?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
//       default:
//         return v;
//     }
//   }
// };
export const returnValuewithType = (v, type) => {
  if (v && v != "" && v != undefined && v != null) {
    switch (type) {
      case "DateTime": return new Date(v) != "Invalid Date" ? moment(v).format("DD/MM/YYYY - hh:mm A") : v
      case "Date": return new Date(v) != "Invalid Date" ? moment(v).format("DD-MM-YYYY") : v
      case "Phone":
        let n = v?.replace("+91", "")?.replace(/[^0-9]/g, "")?.replace(/\s+/g, '');
        let l = n?.length;
        if (l <= 5) return n?.replace(/(\d{1})/, "+91 $1", "")
        else if (l > 5 && l <= 10) return n?.replace(/(\d{5})(\d{1})/, "+91 $1 $2", "")
        break
      // return v?.toString()?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString":
        v?.toString();
      default:
        return v;
    }
  }
  return "";
};
export const logOut = () => {
  sessionStorage.clear();
  window.location.pathname = "/";
};
export const jwtDecodedData = () => {
  if (
    sessionStorage.getItem("token") &&
    sessionStorage.getItem("token") != ""
  ) {
    let res = jwt_decode(sessionStorage.getItem("token"));
    return res;
  }
  return {};
};

export const encryptData = (data) => {
  let en = CryptoJS.AES.encrypt(data?.toString(), "COLINA")?.toString();
  // var encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  // var decoded = CryptoJS.enc.Hex.parse(encoded).toString(CryptoJS.enc.Base64);
  // var decrypted = CryptoJS.AES.decrypt(decoded, "COLINA").toString(CryptoJS.enc.Utf8);
  // let en = CryptoJS.AES.encrypt(data.toString(), 'COLINA', {
  //   mode: CryptoJS.mode.ECB,
  //   padding: CryptoJS.pad.NoPadding
  // }).toString();
  let encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  return encoded;
};

export const decryptData = (encoded) => {
  let temp = {};
  Object.entries(encoded)?.map(([key, value], i) => {
    if (i > 0) {
      let decoded1 = CryptoJS.enc.Hex.parse(value).toString(
        CryptoJS.enc.Base64
      );
      let decrypted1 = CryptoJS.AES.decrypt(decoded1, "COLINA").toString(
        CryptoJS.enc.Utf8
      );
      temp[key] = decrypted1;
    }
  });
  return temp;
};

export const getUserfromSS = () => {
  return JSON.parse(sessionStorage.getItem("user")) || {};
};
export const getUserfromSSAccessLinks = () => {
  return JSON.parse(sessionStorage.getItem("accessLinks")) || {};
};

export const formatTimeField = (value) => {
  const intValue = parseInt(value, 10);

  if (isNaN(intValue) || value === "") {
    return "00";
  } else if (intValue < 10) {
    return `0${intValue}:00`;
  } else if (intValue > 10) {
    return `${intValue}:00`;
  } else if (intValue >= 10 && intValue <= 59) {
    return intValue.toString();
  } else {
    return "00";
  }
};

export const returnTimeFormat = (h, m,sub) => {
  const nm = (n) => {
    return n>1 && n < 10 ? `0${n}` : n;
  };
  return `${nm(h)} : ${nm(m)} ${sub!=""?`[ ${sub} ]`:""}`;
};
export const filterfunction = (data) => {
  let uniqueIds = [];
  return data.filter(obj => {
    if (!uniqueIds.includes(obj.id)) {
      uniqueIds.push(obj.id);
      return true;
    }
    return false;
  });
}

export const getUniqueByKey = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()] || [];
};