import axios from "axios";
import instance from "../Services/Index.js";
import aiInstance from "../Services/AIIndex.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseURL } from "./Consts.js";
import aisalaryInstance from "../Services/AISalaryIndex.js";
 
export const notify = (status, msg) => {
  toast.configure();
  if (status == true) {
    toast.success(msg);
    return <ToastContainer />;
  } else {
    toast.error(msg);
    return <ToastContainer />;
  }
};
 
export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
     return [];
  }
};

export const getfList = async (url) => {
  try {
    let res = await instance.get(url);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
     return [];
  }
};

export const save = async (url, data) => {
  try {
    let res = await instance.post(url, data);
     notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
     return {};
  }
};
export const aiSave = async (url, data) => {
  try {
    let res = await aiInstance.post(url, data);
     notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
     return {};
  }
};
export const aiSalarySave = async (url, data) => {
  try {
    let res = await aisalaryInstance.post(url, data);
     notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
     return {};
  }
};
export const saveWithoutMessage = async (url, data) => {
  try {
      let res = await instance.post(url, data);
      return res?.data?.status == true ? res : {}
  } catch (e) {
      return {};
  }
};
export const add = async (url, data) => {
  try {
    let res = await instance.post(url, data);
     notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
     return {};
  }
};

export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      return res.data.data;
    } else {
      return {};
    }
  } catch (e) {
     return {};
  }
};
export const checkedFn = (data, value) => {
  return data && data == value ? true : false;
};


export const saveWithoutMessageWithoutLoad = async (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  };
  try {
    let res = await axios?.post(`${baseURL}${url}`, data, { headers });
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};