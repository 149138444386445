export const baseURL = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;
export const chatUrl = process.env.REACT_APP_CHAT_URL;
export const AiUrl=process.env.REACT_APP_API_AI_URL;
export const AiSalaryUrl=process.env.REACT_APP_API_AISALARY_URL;

export const css={
  all:"#935A91",
  approved:"#1EC198",
  pending:"#FFBF65",
  reject:"#D66B8C",
  cancel:"#FF8C78"
}
export const urls = {
  Auth: {
    login: "Authentication/Login",
    SendForgotPasswordMail:"Authentication/SendForgotPasswordMail",
    UpdatePassword:"Authentication/UpdatePassword",
    CreatePassword: "Authentication/SetPassword",
  },
  chat: {
    getAll: "Chat/GetChat",
    save: "Chat/Save",
    read:"Chat/ReadMessages"
  },
  Organization:{
    Save:"Organization/Save",
    GetAll:"Organization/GetAll",
    GetById:"Organization/GetById",
    SaveOrganizationHead:"Organization/SaveOrganizationHead",
    GetOrganizationHeadById:"Organization/GetOrganizationHeadById",
    GetAllOrganizationHead:"Organization/GetAllOrganizationHead"
  },
  
  Employee: {
    Save: "Employee/Save",
    GetById: "Employee/GetById",
    GetAll: "Employee/GetAll",
    GetReportingManagers: "Employee/GetReportingManagers",
    SaveEducation: "Employee/SaveEmployeeEducation",
    GetManagers:"Employee/GetManagers",
    SaveUploads: "Employee/SaveUploads",
    FinalSubmit: "Employee/EmployeeFinalSubmit",
    SaveBank: "Employee/SaveEmployeeBank",
    UpdateStatus: "Employee/UpdateStatus",
    SendMail: "Employee/SendMailToEmployee",
    SaveAdditionalInfo: "Employee/SaveAdditionalInfo",
    AddProfilePhoto:"Employee/AddProfilePhoto",
    GetProfilePhoto:"Employee/GetProfilePhoto",
    GetAllTimeZone:"Employee/GetAllTimeZone",
    SaveEmployeeWorkLocation:"Employee/SaveEmployeeWorkLocation",
    GetEmpWorkLocationByEmpId:"Employee/GetEmpWorkLocationByEmpId",
    GetEmployeeWorkLocationById:"Employee/GetEmployeeWorkLocationById",
    GenerateEmployeeId:"Employee/GenerateEmployeeId",
    AddPhotoGallery:"Employee/AddPhotoGallery",
    GetAllPhotoGallery:"Employee/GetAllPhotoGallery",
    DeletePhotoGallery:"Employee/DeletePhotoGallery",
    DeleteFileUploads:"Employee/DeleteFileUploads",
    ImportFromFiles:"Employee/ImportFromFiles",
    DashBoard:"Employee/DashBoard",
    GetAllEmployeeAttendance:"Employee/GetAllEmployeeAttendance",
   
  },
  Biometric:{
    GetEmployeeTimesheet:"Biometric/GetEmployeeTimesheet",
    GetEmployeeWages:"Biometric/GetEmployeeWages",
    GetEmployeeWagesTranDetails:"Biometric/GetEmployeeWagesTranDetails"
  },
  ISOCategory: {
    Save: "ISOCategory/Save",
    GetAll: "ISOCategory/GetAll",
    GetById: "ISOCategory/GetById",
  },
  ISOForm: {
    Save: "ISOForm/Save",
    GetAll: "ISOForm/GetAll",
    GetById: "ISOForm/GetById",
  },
  FormDetails: {
    Save: "FormDetails/Save",
    GetAll: "FormDetails/GetAll",
    GetById: "FormDetails/GetById",
  },
  Project: {
    Save: "Project/Save",
    GetById: "Project/GetById",
    GetAssignedProjectsForEmployee: "Project/GetAssignedProjectsForEmployee", 
    getAllProjectsWithTeamMembers:"Project/GetAllProjectTeamMembers",
    AssignManager: "Project/AssignManager",
    AddTeamMembers: "Project/AddTeamMembers",
    GetTeamMembers: "Project/GetTeamMembers",
    SaveProjectFiles:"Project/SaveProjectFiles",
    GetProjectFiles:"Project/GetProjectFiles",
    DeleteProjectFiles:"Project/DeleteProjectFiles"
    // "Project/GetAssignedProjectsForEmployee"
    // GetAssignedProjectsForEmployee:"Project/GetAssignedProjectsForEmployee"
  },
  Department: {
    Save: "Department/Save",
    GetAll: "Department/GetAll",
    GetById: "Department/GetById",
  },
  Designation: {
    Save: "Designation/Save",
    GetAll: "Designation/GetAll",
    GetById: "Designation/GetById",
    
  },
  Role: {
    GetAll: "Role/GetAll",
    GetById: "Role/GetById",
    Save: "Role/Save",
  },
  Technology: {
    GetAll: "Technology/GetAll",
  },

  TimeSheet: {
    Save: "TimeSheet/Save",
    GetAll:"TimeSheet/GetAll",
    GetManageTimesheetList: "TimeSheet/GetManageTimesheetList ",
    GetById: "TimeSheet/GetById",
     UpdateStatus:"Timesheet/UpdateStatus",
    UpdateFinalSubmit: "Timesheet/UpdateFinalSubmit",
    GetTimesheetDataForExcel:"TimeSheet/GetTimesheetDataForExcel",
    GetMyTimesheet:"TimeSheet/GetMyTimesheet",
    SendTimesheetReminderMail:"Timesheet/SendTimesheetReminderMail",
    GetTimesheetTranById:"Timesheet/GetTimesheetTranById",
    GetEmpListForManager:"Timesheet/GetEmpListForManager"
  },
  LeaveType:{
    GetAll: "LeaveType/GetAll",
  } ,
  Leaves:{
    Save:"Leaves/Save",
    GetEmployeeBalanceLeaves:"Leaves/GetEmployeeBalanceLeaves",
    GetAll:"Leaves/GetAll",
    GetById:"Leaves/GetById",
    GetLeavesForManager:"Leaves/GetLeavesForManager",
    UpdateStatus:"Leaves/UpdateStatus",
    GetLeaveSummary:"Leaves/GetLeaveSummary",
    GetLeavesForAdmin:"Leaves/GetLeavesForAdmin",
    AddSickLeaveDoc:"Leaves/AddSickLeaveDoc",
    GetSickLeaveDoc:"Leaves/GetSickLeaveDoc",
    DeleteSickLeaveDoc:"Leaves/DeleteSickLeaveDoc",
    GetLeavesData:"Leaves/GetLeavesData"

  },
  
  LeaveBalance:{
    Save:"LeaveBalance/Save",
    getAll:"LeaveBalance/getAll",
    AssignUnAssignBulkLeaves:"LeaveBalance/AssignUnAssignBulkLeaves"
  },
  Holidays:{
    GetAll:"Holidays/GetAll",
    Save:"Holidays/Save",
    GetById:"Holidays/GetById",
    DeleteHolidayById:"Holidays/DeleteHolidayById"
  },
  Announcement:{
    Save:"Announcement/Save",
    GetAll:"Announcement/GetAll",
    DeleteAnnouncementById: "Announcement/DeleteAnnouncementById",
    GetById:"Announcement/GetById"
  } ,
  Client:{
    Save:"Client/Save",
    GetAll:"Client/GetAll",
    GetById:"Client/GetById",
    UpdateStatus:"Client/UpdateStatus",
  },
  Designation:{
    Save:"Designation/Save",
    GetAll:"Designation/GetAll",
    GetById:"Designation/GetById",
    ImportFromFile:"Designation/ImportFromFile"
  },
  WorkLocation:{
    GetAll:"WorkLocation/GetAll",
    GetById:"WorkLocation/GetById",
    GetEmpWorkLocationById:"WorkLocation/GetEmpWorkLocationById",
    Save:"WorkLocation/Save",
    },
  
    HRPermission:{
      Save:"HRPermission/Save",
      GetById:"HRPermission/GetById",
      GetAll:"HRPermission/GetAll",
      UpdateStatus:"HRPermission/UpdateStatus",
      GetPermissionForManager:"HRPermission/GetPermissionForManager",
      GetPermissionForAdmin:"HRPermission/GetPermissionForAdmin"
    },
    userAccess:{
      getbyId:"UserAccess/GetRecursiveAccessLinks",
      save:"UserAccess/UpdateAccessLinkStatus",
      getUserAccessLinks:"UserAccess/SubscriptionUserAccessLinks"

    },
    Salary:{
      SaveSalaryTrack:"Salary/SaveSalaryTrack",
      GetAllSalaryTrack:"Salary/GetAllSalaryTrack",
      GetByIdSalaryTrack: "Salary/GetByIdSalaryTrack"
    },
    Assets:{
      GetById:"Assets/GetById",
      GetAll:"Assets/GetAll",
      Save:"Assets/Save"
    },
    TaskType:{
      Save:"TaskType/Save",
      GetById:"TaskType/GetById",
      GetAll:"TaskType/GetAll"

    }

};
