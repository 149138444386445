import { getUserfromSS } from "../Config/SmallFunctions";

const initalState = {
  loggedInUserId: getUserfromSS()?.empId || 0,
  messages: []
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATE_LOGGEDINUSER": return { ...state, loggedInUserId: action?.payload };
    case "UPDATE_MESSAGES": return { ...state, messages: action?.payload };
    default: return state;
  }
};

export { reducer };